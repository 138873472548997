<template>
  <div>
    <div class="commonControl-body">
      <el-button type="primary" @click="addFormButton()">新增虚拟坐标</el-button>
      <common-table
        :tableData="tableData"
        :tableButton="tableButton"
        :tableLabel="tableLabel"
        @edit="editFirstLevel"
      ></common-table>
      <div class="layoutBorder">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 点击事件触发 -->
    <el-dialog title="编辑" :visible.sync="dialogFormVisibleEdit">
      <el-form :model="formEdit">
        <el-form-item label="id" label-width="120px">
          <el-input
            v-model="formEdit.lot_id"
            autocomplete="off"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="slam关联id" label-width="120px">
          <el-input v-model="formEdit.parking_lot" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="slam关联id" label-width="120px">
          <el-input v-model="formEdit.remarks" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="updateFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增虚拟坐标" :visible.sync="dialogFormVisibleAdd">
      <el-form :model="formAdd">
        <el-form-item label="车库id" label-width="120px">
          <el-input
            v-model="formAdd.garage_id"
            autocomplete="off"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="slam坐标系id" label-width="120px">
          <el-input v-model="formAdd.parking_lot" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="slam坐标备注" label-width="120px">
          <el-input v-model="formAdd.remarks" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="createFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  garage_slam_get,
  garage_slam_create,
  garage_slam_update,
  garage_slam_delete,
} from "@/api/manage/business/garage/garage.js";
import CommonTable from "frontelementpackage/src/CommonTableButton.vue";

export default {
  name: "start",
  data() {
    return {
      query: {
        querypage: 1, //获取页码
        pagesize: 10, //每页返回数量
        keyword: "", //搜索 可搜邮箱 用户名 手机号
      },
      //分页数据
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData: [],
      dialogFormVisibleEdit: false,
      formEdit: { group_info: "" },
      dialogFormVisibleAdd: false,
      formAdd: {},
      tableLabel: [
        {
          prop: "id",
          label: "IndexID(关联用索引ID)",
          width: 250,
        },
        {
          prop: "parking_lot",
          label: "lotID(车库系统中的虚拟ID)",
          width: 250,
        },
        {
          prop: "remarks",
          label: "备注",
          width: 350,
        },
      ],
      tableButton: [
        {
          name: "编辑",
          type: "primary",
          size: "small",
        },

        {
          name: "删除",
          type: "danger",
          size: "small",
        },
      ],
    };
  },
  components: {
    CommonTable,
  },
  created() {
    this.getFirstLevel();
  },
  methods: {
    editFirstLevel(row, methods) {
      if (methods == "编辑") {
        this.editUser(row);
      }
      if (methods == "删除") {
        this.delUser(row);
      }
    },
    editUser(row) {
      this.formEdit = {
        lot_id: row.id,
        parking_lot: row.parking_lot,
        remarks: row.remarks,
      };

      this.dialogFormVisibleEdit = true;
    },
    delUser(row) {
      let ids = row.id;
      console.log({ group_id: ids });
      this.$confirm("是否确定删除", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "删除",
        cancelButtonText: "放弃删除",
      })
        .then(() => {
          garage_slam_delete({ lot_id: ids }).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功删除");
              this.getFirstLevel();
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃删除" : "停留在当前",
          });
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: "",
      };
      //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
      // user_group_manage_index(this.query).then((res) => {
      //   console.log(this.tableData, "new");
      //   this.tableData = res.data.result;
      // });
    },
    handleSizeChange(val) {
      this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
      // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: "",
      };
      console.log(this.query);
      // user_group_manage_index(this.query).then((res) => {
      //   console.log(this.tableData, "new");
      //   this.tableData = res.data.result;
      // });
    },

    createFirstLevel() {
      garage_slam_create(this.formAdd).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功添加");
          this.getFirstLevel();
        } else {
          this.$message.info(res.msg);
        }
      });
      console.log(this.formAdd);
      this.dialogFormVisibleAdd = false;
    },
    addFormButton() {
      this.formAdd.garage_id = this.$parent.$route.query.garage_id;
      this.dialogFormVisibleAdd = true;
    },
    updateFirstLevel() {
      console.log(this.formEdit);
      garage_slam_update(this.formEdit).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功修改");
          this.getFirstLevel();
        } else {
          this.$message.info(res.msg);
        }
      });
      this.dialogFormVisibleEdit = false;
    },
    getFirstLevel() {
      garage_slam_get({ garage_id: this.$parent.$route.query.garage_id }).then(
        (res) => {
          console.log(this.tableData, "new");
          this.tableData = res.data;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
