<template>
    <div>
        <div class="commonControl-body">
            <el-button type="primary" @click="addFormButton()"
                >新增入口</el-button
            >
            <common-table
                :tableData="tableData"
                :tableDropdown="tableDropdown"
                :tableLabel="tableLabel"
                @edit="editFirstLevel"
            ></common-table>
            <div class="layoutBorder">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[5, 10, 20, 40]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>
    
        <el-dialog title="新增车库入口" :visible.sync="dialogFormVisibleAdd">
            <el-form :model="formAdd">
                <el-form-item label="车库id" label-width="120px">
                    <el-input
                        v-model="formAdd.garage_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="分区id" label-width="120px">
                    <el-select
                        v-model="formAdd.partition_id"
                        placeholder="请选择区域"
                        clearable
                    >
                        <el-option
                            v-for="(item, index) in partAreaData"
                            :key="index + item.id"
                            :label="`${item.partition_name}区`"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="SLAMID" label-width="120px">
                    <el-select
                        v-model="formAdd.parking_lot_id"
                        placeholder="请选择区域"
                        clearable
                    >
                        <el-option
                            v-for="(item, index) in slamData"
                            :key="index + item.id"
                            :label="`${item.remarks}区`"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出入口名" label-width="120px">
                    <el-input
                        v-model="formAdd.entry_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleAdd = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="createFirstLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <el-dialog title="车牌ocr修改" :visible.sync="dialogFormVisibleOcrEdit">
            <el-form :model="formOcrEdit">
                <el-form-item label="车库id" label-width="120px">
                    <el-input
                        v-model="formOcrEdit.entrance_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="识别结果" label-width="120px">
                    <el-input
                        v-model="formOcrEdit.ocrresult"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleOcrEdit = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="EditOcrLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog title="修改关联id" :visible.sync="dialogFormVisibleSlamEdit">
            <el-form :model="formSlamEdit">
                <el-form-item label="车库id" label-width="120px">
                    <el-input
                        v-model="formSlamEdit.entrance_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="slam id" label-width="120px">
                    <el-input
                        v-model="formSlamEdit.lot_id"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleSlamEdit = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="EditSlamLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <el-dialog
            title="修改微信key"
            :visible.sync="dialogFormVisibleWechatEdit"
        >
            <el-form :model="formWechatEdit">
                <el-form-item label="车库id" label-width="120px">
                    <el-input
                        v-model="formWechatEdit.entrance_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="微信key" label-width="120px">
                    <el-input
                        v-model="formWechatEdit.qrcode_key"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleWechatEdit = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="EditWechatLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <el-dialog
            title="修改出入口"
            :visible.sync="dialogFormVisibleEntranceEdit"
        >
            <el-form :model="formEntranceEdit">
                <el-form-item label="车库id" label-width="120px">
                    <el-input
                        v-model="formEntranceEdit.entrance_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="出入口名字" label-width="120px">
                    <el-input
                        v-model="formEntranceEdit.entry_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="分区id" label-width="120px">
                    <el-input
                        v-model="formEntranceEdit.partition_id"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleEntranceEdit = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="EditEntranceLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    garage_entrance_get,
    garage_entrance_create,
    garage_entrance_ocr_update,
    garage_entrance_slam_update,
    garage_entrance_qrcode_update,
    garage_entrance_update,
    garage_entrance_status_update,
    garage_entrance_reset_update,
    garage_partArea_get, //获取分区
    garage_slam_get //获取虚拟坐标
} from '@/api/manage/business/garage/garage.js';
import CommonTable from 'frontelementpackage/src/CommonTableDropdown.vue';

export default {
    name: 'entrance',
    data() {
        return {
            dialogFormVisibleEntranceEdit: false,
            formEntranceEdit: {
                entrance_id: null,
                entry_name: null,
                partition_id: null
            },
            dialogFormVisibleWechatEdit: false,
            formWechatEdit: { entrance_id: null, qrcode_key: null },
            dialogFormVisibleSlamEdit: false,
            formSlamEdit: { entrance_id: null, lot_id: null },
            dialogFormVisibleOcrEdit: false,
            formOcrEdit: { entrance_id: null, ocrresult: null },
            query: {
                querypage: 1, //获取页码
                pagesize: 10, //每页返回数量
                keyword: '' //搜索 可搜邮箱 用户名 手机号
            },
            //分页数据
            currentPage: 1, //初始页
            pagesize: 10, //    每页的数据
            tableData: [],
            dialogFormVisibleEdit: false,
            formEdit: { group_info: '' },
            dialogFormVisibleAdd: false,
            formAdd: {},
            tableLabel: [
                {
                    prop: 'id',
                    label: 'id'
                },
                {
                    prop: 'entry_name',
                    label: '出入口名'
                },
                {
                    prop: 'qrcode_key',
                    label: '二维码key值'
                },
                {
                    prop: 'status',
                    label: '启动状态'
                },
                {
                    prop: 'work_status',
                    label: '工作状态'
                },
                {
                    prop: 'work_type',
                    label: '当前状态'
                },
                {
                    prop: 'carport_ocr',
                    label: '车牌'
                },
                {
                    prop: 'parking_lot_index',
                    label: '关联'
                },
                {
                    // prop: "",
                    label: '分区'
                },
                {
                    prop: 'update_time',
                    label: '最近一次更新时间'
                }
            ],
            tableDropdown: [
                {
                    id: '1',
                    name: '修改关联id'
                },
                {
                    id: '2',
                    name: '手动上报车牌'
                },
                {
                    id: '3',
                    name: '禁用该出口'
                },
                {
                    id: '4',
                    name: '恢复启用'
                },
                {
                    id: '5',
                    name: '修改微信key'
                },
                {
                    id: '6',
                    name: '修改出入口信息'
                },
                {
                    id: '7',
                    name: '重置出口状态和占用'
                }
            ],
            partAreaData: [],
            slamData:[]
        };
    },
    components: {
        CommonTable
    },
    created() {
        this.getFirstLevel();
    },
    methods: {
        editFirstLevel(row, methods) {
            if (methods == '手动上报车牌') {
                this.dialogFormVisibleOcrEdit = true;
                this.formOcrEdit.entrance_id = row.id;
                this.formOcrEdit.ocrresult = row.carport_ocr;
            }
            if (methods == '修改关联id') {
                this.dialogFormVisibleSlamEdit = true;
                this.formSlamEdit.entrance_id = row.id;
            }
            if (methods == '修改微信key') {
                this.dialogFormVisibleWechatEdit = true;
                this.formWechatEdit.entrance_id = row.id;
            }
            if (methods == '修改出入口信息') {
                this.dialogFormVisibleEntranceEdit = true;
                this.formEntranceEdit.entrance_id = row.id;
            }
            if (methods == '重置出口状态和占用') {
                this.ResetLevel(row);
            }
            if (methods == '禁用该出口') {
                this.EditNotUseLevel(row);
            }
            if (methods == '恢复启用') {
                this.EditIsUseLevel(row);
            }
        },
        ResetLevel(row) {
            this.$confirm('是否重置', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '重置',
                cancelButtonText: '放弃重置'
            })
                .then(() => {
                    garage_entrance_reset_update({
                        entrance_id: row.id,
                        status: 1
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message.success('成功重置');
                            this.$router.go(0); // 更新当前页面
                        } else {
                            this.$message.info(res.msg);
                        }
                    });
                })
                .catch(action => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '放弃重置' : '停留在当前'
                    });
                });
        },
        EditNotUseLevel(row) {
            this.$confirm('是否禁用该出口', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '禁用',
                cancelButtonText: '放弃禁用'
            })
                .then(() => {
                    garage_entrance_status_update({
                        entrance_id: row.id,
                        status: 1
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message.success('成功禁用');
                            this.$router.go(0); // 更新当前页面
                        } else {
                            this.$message.info(res.msg);
                        }
                    });
                })
                .catch(action => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '放弃禁用' : '停留在当前'
                    });
                });
        },
        EditIsUseLevel(row) {
            this.$confirm('是否启用该出口', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '启用',
                cancelButtonText: '放弃启用'
            })
                .then(() => {
                    garage_entrance_status_update({
                        entrance_id: row.id,
                        status: 0
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message.success('成功启用');
                            this.$router.go(0); // 更新当前页面
                        } else {
                            this.$message.info(res.msg);
                        }
                    });
                })
                .catch(action => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '放弃禁用' : '停留在当前'
                    });
                });
        },
        EditEntranceLevel() {
            this.dialogFormVisibleEntranceEdit = false;
            console.log(this.formEntranceEdit);
            garage_entrance_update(this.formEntranceEdit).then(res => {
                if (res.code == 200) {
                    this.$message.success('成功修改出入口信息');
                    this.getFirstLevel();
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        EditWechatLevel() {
            this.dialogFormVisibleWechatEdit = false;
            console.log(this.formWechatEdit);
            garage_entrance_qrcode_update(this.formWechatEdit).then(res => {
                if (res.code == 200) {
                    this.$message.success('成功修改微信key');
                    this.getFirstLevel();
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        EditSlamLevel() {
            this.dialogFormVisibleSlamEdit = false;
            console.log(this.formSlamEdit);
            // garage_entrance_ocr_update
            garage_entrance_slam_update(this.formSlamEdit).then(res => {
                if (res.code == 200) {
                    this.$message.success('成功修改slam关联');
                    this.getFirstLevel();
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        EditOcrLevel() {
            this.dialogFormVisibleOcrEdit = false;
            console.log(this.formOcrEdit);
            garage_entrance_ocr_update;
            garage_entrance_ocr_update(this.formOcrEdit).then(res => {
                if (res.code == 200) {
                    this.$message.success('成功修改车牌数据');
                    this.getFirstLevel();
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        delUser(row) {
            let ids = row.id;
            console.log({ group_id: ids });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.query = {
                querypage: this.currentPage,
                pagesize: this.pagesize,
                keyword: ''
            };
        },
        handleSizeChange(val) {
            this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
            // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
            this.query = {
                querypage: this.currentPage,
                pagesize: this.pagesize,
                keyword: ''
            };
            console.log(this.query);
        },

        createFirstLevel() {
            console.log(this.formAdd)
            garage_entrance_create(this.formAdd).then(res => {
                if (res.code == 200) {
                    this.$message.success('成功添加');
                    this.getFirstLevel();
                } else {
                    this.$message.info(res.msg);
                }
            });

            this.dialogFormVisibleAdd = false;
        },
        addFormButton() {
            this.formAdd.garage_id = this.$parent.$route.query.garage_id;
            this.dialogFormVisibleAdd = true;
        },
        getFirstLevel() {
            console.log(this.$parent.$route.query.garage_id);
            garage_entrance_get({
                garage_id: this.$parent.$route.query.garage_id
            }).then((res) => {
                console.log(res);
                this.tableData = res.data;
            });
            //获取分区信息
            garage_partArea_get({
                garage_id: this.$parent.$route.query.garage_id
            }).then((res) => {
                console.log(res.data, '分区id数据');
                this.partAreaData = res.data;
            });
            //获取虚拟坐标信息
            garage_slam_get({
                garage_id: this.$parent.$route.query.garage_id
            }).then((res) => {
                console.log( res.data, 'new');
                this.slamData = res.data;
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
