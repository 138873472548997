<template>
    <div>
        <el-button type="primary" @click="dialogAmrFormVisible = true"
            >新增机器人设备</el-button
        >
        <CommonPopSelectorVue
            title="选择车库系统"
            :visible.sync="dialogAmrFormVisible"
            :table-data="tableData"
            :table-columns="tableColumns"
            :table-button="tableButton"
            @selectItem="handleSelectItem"
        />

        <el-table :data="lotTableData" style="width: 100%">
            <el-table-column
                prop="equipment_id"
                label="设备ID"
                width="240px"
                align="left"
            >
            </el-table-column>
            <el-table-column
                prop="agv_status"
                label="机器人状态"
                width="230px"
                align="left"
            >
            </el-table-column>
            <el-table-column
                prop="manufacturer"
                label="生产点"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="product.product_name"
                label="产品名"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="product.product_series"
                label="设备型号"
                align="left"
            ></el-table-column>

            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button
                        type="danger"
                        @click="deleteRelevanceAmr(scope.row)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {
    garage_agv_query,
    garage_agv_relevance,
    garage_agv_delete_relevance
} from '@/api/manage/business/garage/garage.js';

import { agv_get } from '@/api/manage/operation/agv/agv.js';
import CommonPopSelectorVue from '@/components/shibo_Common/CommonPopSelector.vue';
export default {
    props: {
        garage_id: String
    },
    components: { CommonPopSelectorVue },
    data() {
        return {
            lotTableData: [],
            queryData: {
                currentPage: 1,
                pageCount: 10,
                total: 1
            },
            tableData: null,
            dialogAmrFormVisible: false,
            tableColumns: [
                // 动态表格列配置
                { prop: 'equipment_id', label: '机器人-ID' },
                { prop: 'product[product_name]', label: '机器人名称' },
                { prop: 'agv_status', label: '机器人状态' }
            ],
            tableButton: [{ label: '关联', type: 'primary' }],
            selectItem: null
        };
    },
    created() {
        this.getEntranceTabLotIndexData();
        this.getAmrList();
    },
    methods: {
        handleSelectItem(item) {
            this.selectItem = item;
            this.relevanceAmr(item);
        },
        getAmrList() {
            agv_get({
                querypage: this.queryData.currentPage,
                pagesize: this.queryData.pageSize
            }).then(res => {
                if (res.code == 200) {
                    this.queryData.total = res.data.total;
                    this.queryData.currentPage = res.data.currentPage;
                    this.queryData.pageCount = res.data.pageCount;
                    this.tableData = res.data.result;
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        duration: 5 * 1000
                    });
                }
            });
        },
        deleteRelevanceAmr(item) {
            garage_agv_delete_relevance({
                garage_id: this.garage_id,
                equipment_id:item.equipment_id
            }).then((res) => {
              if(res.code == 200){
                this.$message.success('删除关联成功');
                this.$router.go(0);
              }
            });
        },
        relevanceAmr(item) {
            this.dialogAmrFormVisible = false;
            garage_agv_relevance({
                garage_id: this.garage_id,
                equipment_id: item.equipment_id
            })
                .then(() => {
                    this.$router.go(0);
                })
                .catch(err => {
                    console.log(err);
                });
        },
        getGarageInfo() {},
        getEntranceTabLotIndexData() {
            this.$http(
                garage_agv_query({
                    garage_id: this.garage_id
                }),
                res => {
                    console.log(res);
                    if (res.code == 200) {
                        this.lotTableData = res.data;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            duration: 5 * 1000
                        });
                    }
                }
            );
        }
    }
};
</script>
