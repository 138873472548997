<template>
    <div>
        <el-button type="primary" @click="dialogLifterFormVisible = true"
            >新增升降机管理</el-button
        >
        <CommonPopSelectorVue
            title="选择车库系统"
            :visible.sync="dialogLifterFormVisible"
            :table-data="tableData"
            :table-columns="tableColumns"
            :table-button="tableButton"
            @selectItem="handleSelectItem"
        />

        <el-table :data="lotTableData" style="width: 100%">
            <el-table-column
                prop="lifter_id"
                label="升降机ID"
                width="240px"
                align="left"
            >
            </el-table-column>
            <el-table-column
                prop="lifter_model"
                label="升降机型号"
                width="230px"
                align="left"
            >
            </el-table-column>
            <el-table-column
                prop="lifter_name"
                label="升降机名称"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="floor"
                label="升降机层高"
                align="left"
            ></el-table-column>

            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button
                        type="danger"
                        @click="deleteRelevanceLifter(scope.row)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {
    garage_lifter_manage,
    garage_lifter_relevance,
    garage_lifter_delete_relevance
} from '@/api/manage/business/garage/garage.js';

import { lifter_manage_query } from '@/api/manage/operation/lifter_manage/lifter_manage.js';

import CommonPopSelectorVue from '@/components/shibo_Common/CommonPopSelector.vue';
export default {
    props: {
        garage_id: String
    },
    components: { CommonPopSelectorVue },
    data() {
        return {
            lotTableData: [],
            queryData: {
                currentPage: 1,
                pageCount: 10,
                total: 1
            },
            tableData: null,
            dialogLifterFormVisible: false,
            tableColumns: [
                // 动态表格列配置
                { prop: 'lifter_id', label: '升降机-ID' },
                { prop: 'lifter_name', label: '升降机名称' },
                { prop: 'lifter_model', label: '升降机型号' }
            ],
            tableButton: [{ label: '关联', type: 'primary' }],
            selectItem: null
        };
    },
    created() {
        this.getEntranceTabLotIndexData();
        this.getLifterList();
    },
    methods: {
        handleSelectItem(item) {
            this.selectItem = item;
            this.relevanceLifter(item);
        },
        getLifterList() {
            lifter_manage_query({
                querypage: this.queryData.currentPage,
                pagesize: this.queryData.pageSize
            }).then(res => {
                if (res.code == 200) {
                    this.queryData.total = res.data.total;
                    this.queryData.currentPage = res.data.currentPage;
                    this.queryData.pageCount = res.data.pageCount;
                    this.tableData = res.data.result;
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        duration: 5 * 1000
                    });
                }
            });
        },
        deleteRelevanceLifter(item) {
            garage_lifter_delete_relevance({
                garage_id: this.garage_id,
                lifter_id: item.lifter_id
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success('删除关联成功');
                    this.$router.go(0);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        relevanceLifter(item) {
            this.dialogLifterFormVisible = false;
            garage_lifter_relevance({
                garage_id: this.garage_id,
                lifter_id: item.lifter_id
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$message.success('关联成功');
                        this.$router.go(0);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        getGarageInfo() {},
        getEntranceTabLotIndexData() {
            this.$http(
                garage_lifter_manage({
                    garage_id: this.garage_id
                }),
                res => {
                    console.log(res);
                    if (res.code == 200) {
                        this.lotTableData = res.data;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            duration: 5 * 1000
                        });
                    }
                }
            );
        }
    }
};
</script>
