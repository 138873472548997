<template>
    <div>
        <!-- 编辑车库基本信息弹窗 -->
        <el-dialog title="编辑车库信息" :visible.sync="dialogFormVisibleEdit">
            <el-form :model="formEdit">
                <el-form-item label="车库id" label-width="120px">
                    <el-input
                        v-model="itemData.garage_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车库名" label-width="120px">
                    <el-input
                        v-model="formEdit.info_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车库电话" label-width="120px">
                    <el-input
                        v-model="formEdit.info_phone"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车库简介" label-width="120px">
                    <el-input
                        v-model="formEdit.info_introduce"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车库地址" label-width="120px">
                    <el-input
                        v-model="formEdit.info_address"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车库楼层" label-width="120px">
                    <el-input
                        v-model="formEdit.info_floor"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车位数" label-width="120px">
                    <el-input
                        v-model="formEdit.space"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleEdit = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="updateFirstLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <!-- 设置车库系统版本 -->

        <product-pop-select
            title="选择车库系统"
            :visible.sync="popSelectorVisible"
            :tableData="garageSystemList"
            :table-columns="tableColumns"
            :table-button="tableButton"
            @selectItem="handleSelectItem"
            @selcetChange="handleSelectChange"
        />

        <infoHeader :content_name="'车库配置信息'" />

        <el-card shadow="hover" class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ itemData.info_name }}</span>
                <el-button style="float: right" @click="openEdit()"
                    >修改车库基础信息</el-button
                >
                <el-button
                    style="margin-right: 20px; float: right"
                    @click="popSelectorVisible = true"
                    >设置车库系统版本</el-button
                >
                <!-- <el-button style="float: right" @click="toParkingInfo()" type="primary"
                    >查看车位详情</el-button
                > -->
            </div>
            <el-descriptions
                class="margin-top"
                :column="4"
                direction="vertical"
            >
                <el-descriptions-item label="车库ID"
                    ><div class="descriptions_content">
                        {{ itemData.garage_id }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="车库名"
                    ><div class="descriptions_content">
                        {{ itemData.info_name }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="车库介绍" span="3"
                    ><div class="descriptions_content">
                        {{ itemData.info_introduce }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="车库软件版本"
                    ><div class="descriptions_version">
                        {{ itemData.garage_software_product.product_name }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="车库地址"
                    ><div class="descriptions_content">
                        {{ itemData.info_address }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="联系电话"
                    ><div class="descriptions_content">
                        {{ itemData.info_phone }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="单位状态"
                    ><div class="descriptions_content">
                        {{ itemData.company_status }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="车位数"
                    ><div class="descriptions_content">
                        {{ itemData.space }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="楼层数"
                    ><div class="descriptions_content">
                        {{ itemData.info_floor }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item
                    label="开始营业时间"
                    v-if="itemData.start_business_time"
                    ><div class="descriptions_content">
                        {{ itemData.start_business_time }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="出入方案"
                    ><div class="descriptions_content">
                        {{ itemData.access_plan_id }}
                    </div></el-descriptions-item
                >
            </el-descriptions>
        </el-card>

        <el-tabs v-model="tabActiveName" @tab-click="tabHandleClick">
            <el-tab-pane label="车库出入口管理" name="entrance">
                <entrance
                    v-if="show_entrance"
                    :garage_id="itemData.garage_id"
                />
            </el-tab-pane>
            <el-tab-pane label="虚拟坐标管理" name="lot">
                <lot v-if="show_lot" :garage_id="itemData.garage_id" />
            </el-tab-pane>
            <el-tab-pane label="车库机器人管理" name="agv">
                <agv v-if="show_agv" :garage_id="itemData.garage_id" />
            </el-tab-pane>
            <el-tab-pane label="车库升降机管理" name="lifter"
                ><lifter v-if="show_lifter" :garage_id="itemData.garage_id"
            /></el-tab-pane>
            <el-tab-pane label="车库监控管理" name="monitoring"
                ><monitoring
                    v-if="show_monitoring"
                    :garage_id="itemData.garage_id"
            /></el-tab-pane>
            <el-tab-pane label="云端连接配置" name="cloud_clinet"
                ><cloudsetting
                    v-if="show_cloudsetting"
                    :garage_id="itemData.garage_id"
            /></el-tab-pane>
            <el-tab-pane label="车库分区管理" name="part_area"
                ><partarea
                    v-if="show_part_area"
                    :garage_id="itemData.garage_id"
            /></el-tab-pane>
            <el-tab-pane label="车库车位管理" name="parking"
                ><parking v-if="show_parking" :garage_id="itemData.garage_id"
            /></el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import entrance from './garageManageInfoPage/entrance.vue';
import lot from './garageManageInfoPage/lot.vue';
import agv from './garageManageInfoPage/agv.vue';
import lifter from './garageManageInfoPage/lifter.vue';
import monitoring from './garageManageInfoPage/monitoring.vue';
import cloudsetting from './garageManageInfoPage/cloudsetting.vue';
import partarea from './garageManageInfoPage/part_area.vue';
import parking from './garageManageInfoPage/parking.vue';

// 组件引入
import { product_get } from '@/api/manage/operation/product/product.js';
import {
    garage_get,
    garage_software
} from '@/api/manage/business/garage/garage.js';
import ProductPopSelect from '../productLibrary/components/productPopSelect.vue';

export default {
    name: 'garageInfo',
    data() {
        return {
            itemData: { garage_software_product: {} },
            show_entrance: true,
            show_lot: false,
            show_agv: false,
            show_lifter: false,
            show_monitoring: false,
            show_cloudsetting: false,
            show_part_area: false,
            show_parking: false,
            tabActiveName: 'entrance',
            formEdit: {},
            dialogFormVisibleEdit: false,
            option: {},
            popSelectorVisible: false, // 车库系统弹窗
            tableColumns: [
                // 动态表格列配置
                { prop: 'product_name', label: '产品名' },
                { prop: 'product_series', label: '产品系列' },
                { prop: 'product_type', label: '产品类型' }
                // 其他列配置项
            ],
            tableButton: [
                { label: '选择', type: 'primary' },
                { label: '删除', type: 'danger' }
            ],
            selectItem: undefined,
            garage_id: undefined,
            garageSystemList: undefined
        };
    },
    components: {
        entrance,
        lot,
        agv,
        lifter,
        monitoring,
        cloudsetting,
        partarea,
        parking,
        ProductPopSelect
    },
    created() {
        this.garage_id = this.$route.query.garage_id;
        this.getGarageData(this.garage_id);
        this.getGarageSystemList();
    },
    mounted() {},
    methods: {
        getGarageData(garage_id) {
            garage_get({
                garage_id: garage_id
            }).then(res => {
                if (res.code == 200) {
                    this.itemData = res.data;
                }
            });
        },
        getGarageSystemList(ids) {
            product_get({ product_class_id: ids }).then(res => {
                this.garageSystemList = res.data.result;
                console.log(this.garageSystemList, 11111);
            });
        },
        tabHandleClick(tab) {
            this.show_entrance = false;
            this.show_lot = false;
            this.show_agv = false;
            this.show_lifter = false;
            this.show_monitoring = false;
            this.show_cloudsetting = false;
            if (tab.paneName == 'entrance') {
                this.show_entrance = true;
            }
            if (tab.paneName == 'lot') {
                this.show_lot = true;
            }
            if (tab.paneName == 'agv') {
                this.show_agv = true;
            }
            if (tab.paneName == 'lifter') {
                this.show_lifter = true;
            }
            if (tab.paneName == 'monitoring') {
                this.show_monitoring = true;
            }
            if (tab.paneName == 'cloud_clinet') {
                this.show_cloudsetting = true;
            }
            if (tab.paneName == 'part_area') {
                this.show_part_area = true;
            }
            if (tab.paneName == 'parking') {
                this.show_parking = true;
            }
        },
        openEdit() {
            this.formEdit = {
                garage_id: this.itemData.garage_id,
                info_name: this.itemData.info_name,
                info_phone: this.itemData.info_phone,
                info_address: this.itemData.info_address,
                info_introduce: this.itemData.info_introduce,
                space: this.itemData.space,
                info_floor: this.itemData.info_floor
            };
            this.dialogFormVisibleEdit = true;
            
        },
        sumbitEdit() {
            Reflect.deleteProperty(this.formData.edit, 'equipment_type');
            Reflect.deleteProperty(this.formData.edit, 'model_brand');
            Reflect.deleteProperty(this.formData.edit, 'product_type');
            this.formData.edit['annotation'] = '';

            this.formData.edit['equipment_id'] = this.formData.edit['id'];
            this.dialogFormVisibleEdit = false;
        },
        handleSelectItem(item) {
            this.selectItem = item;
            garage_software({
                software_product_id: item.id,
                garage_id: this.garage_id
            }).then(() => {
                this.$router.go(0);
            });
        },
        handleSelectChange(ids) {
            this.getGarageSystemList(ids);
        },
        toParkingInfo() {
            this.$router.push({
                name: 'garage_Parking'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.box-card {
    margin-bottom: 40px;
}
.descriptions_content {
    margin-bottom: 25px;
}
.clearfix {
    // padding-bottom: 10px;
    line-height: 40px;
}
</style>
