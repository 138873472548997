<template>
    <div>
        <div class="common-wrapper">
            <div class="parking-header">
                <el-form
                    ref="form"
                    :model="form"
                    label-width="80px"
                    :inline="true"
                >
                    <el-form-item label="车位id">
                        <el-input v-model="form.id"></el-input>
                    </el-form-item>
                    <el-form-item label="指定楼层:">
                        <el-select
                            v-model="form.floor"
                            placeholder="请选择楼层"
                            clearable
                        >
                            <el-option label="2楼" value="2"></el-option>
                            <el-option label="3楼" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="指定区域:">
                        <el-select
                            v-model="form.area"
                            placeholder="请选择区域"
                            clearable
                        >
                            <el-option label="C区" value="C"></el-option>
                            <el-option label="D区" value="D"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="车位状态:">
                        <el-select
                            v-model="form.status"
                            placeholder="请选择状态"
                            clearable
                        >
                            <el-option label="空闲" value="0"></el-option>
                            <el-option label="已派位，暂无停车" value="1"></el-option>
                            <el-option label="正在停车中" value="2"></el-option>
                            <el-option label="已被使用" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-button style="margin-left: 20px" type="primary"
                        >查询</el-button
                    >
                </el-form>
            </div>
            <div class="parking-list">
                <common-table
                    :tableData="tableData"
                    :tableButton="tableButton"
                    :tableColumns="tableLabel"
                >

                </common-table>
            </div>
            <!-- <div class="parking-body"> 
                <div class="parking-area">
                    <div class="parking-area__item">1</div>
                    <div class="parking-area__item">2</div>
                    <div class="parking-area__item">3</div>
                </div>
            </div> -->
        </div>
        <!-- 点击事件触发 -->
        <el-dialog title="操作车位信息" :visible.sync="dialogFormVisibleEdit">
            <el-form :model="formEdit">
                <el-form-item label="分区id" label-width="120px">
                    <el-input
                        v-model="formEdit.partition_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="分区名称" label-width="120px">
                    <el-input
                        v-model="formEdit.partition_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleEdit = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="updateFirstLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog title="新增分区" :visible.sync="dialogFormVisibleAdd">
            <el-form :model="formAdd">
                <el-form-item label="分区id" label-width="120px">
                    <el-input
                        v-model="formAdd.garage_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="分区名称" label-width="120px">
                    <el-input
                        v-model="formAdd.partition_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleAdd = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="createFirstLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    garage_partArea_get,
    garage_partArea_create,
    garage_partArea_delete,
    garage_partArea_update
} from '@/api/manage/business/garage/garage.js';

import CommonTable from '@/components/shibo_Common/CommonTable.vue';

export default {
    name: 'parking',
    data() {
        return {
            query: {
                querypage: 1, //获取页码
                pagesize: 10, //每页返回数量
                keyword: '' //搜索 可搜邮箱 用户名 手机号
            },
            //分页数据
            form: {
                id:undefined,
                floor: '2',
                area: undefined,
                status:undefined
            },
            tableData: [
                
            ],
            dialogFormVisibleEdit: false,
            formEdit: { group_info: '' },
            dialogFormVisibleAdd: false,
            formAdd: {},
            tableLabel: [
                {
                    prop: 'floor',
                    label: '所在楼层',
                },
                {
                    prop: 'id',
                    label: 'id',
                },
                {
                    prop: 'status',
                    label: '车位状态',
                },
                {
                    prop: 'area',
                    label: '所在区域',
                }   
            ],
            tableButton: [
                {
                    name: '操作',
                    type: 'primary',
                    size: 'small'
                },
                {
                    name: '删除',
                    type: 'danger',
                    size: 'small'
                }
            ]
        };
    },
    components: {CommonTable},
    created() {
        this.getFirstLevel();
    },
    methods: {
        editFirstLevel(row, methods) {
            if (methods == '操作') {
                this.editUser(row);
            }
            if (methods == '删除') {
                this.delUser(row);
            }
        },
        editUser(row) {
            this.formEdit = {
                partition_id: row.id,
                partition_name: row.partition_name
            };
            this.dialogFormVisibleEdit = true;
        },
        delUser(row) {
            let ids = row.id;
            this.$confirm('是否确定删除', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '删除',
                cancelButtonText: '放弃删除'
            })
                .then(() => {
                    garage_partArea_delete({ partition_id: ids }).then(res => {
                        if (res.code == 200) {
                            this.$message.success('成功删除');
                            this.getFirstLevel();
                        } else {
                            this.$message.info(res.msg);
                        }
                    });
                })
                .catch(action => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '放弃删除' : '停留在当前'
                    });
                });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.query = {
                querypage: this.currentPage,
                pagesize: this.pagesize,
                keyword: ''
            };
        },
        handleSizeChange(val) {
            this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
            // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
            this.query = {
                querypage: this.currentPage,
                pagesize: this.pagesize,
                keyword: ''
            };
        },

        createFirstLevel() {
            let temp = {
                garage_id: this.$parent.$route.query.garage_id,
                partition_name: this.formAdd.partition_name
            };
            garage_partArea_create(temp).then(res => {
                if (res.code == 200) {
                    this.$message.success('成功添加');
                    this.getFirstLevel();
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleAdd = false;
        },
        addFormButton() {
            this.formAdd.garage_id = this.$parent.$route.query.garage_id;
            this.dialogFormVisibleAdd = true;
        },
        updateFirstLevel() {
            console.log(this.formEdit);
            garage_partArea_update(this.formEdit).then(res => {
                if (res.code == 200) {
                    this.$message.success('成功修改');
                    this.getFirstLevel();
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleEdit = false;
        },
        getFirstLevel() {
            console.log(this.$parent.$route.query.garage_id);
            garage_partArea_get({
                garage_id: this.$parent.$route.query.garage_id
            }).then(res => {
                console.log(res.data);
                this.tableData = res.data;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.parking-header {
    margin-top: 10px;
}
.parking-body {
    margin-top: 6px;
    height: 50vh;
    border: 3px solid #e4e7ed;
    border-radius: 4px;

    .parking-area {
        width: 100%;
        height: 50vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        background-color: pink;
    }
    .parking-area__item {
        width: 430px;
        height: 44vh;
        margin-top: 30px;
        background-color: red;
    }
}
</style>
