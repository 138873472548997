<template>
  <div>

     <div class="commonControl-body">
       <el-button type="primary" @click="addFormButton()">新增监控</el-button>
        <common-table
          :tableData="
            tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)
          "
          :tableDropdown="tableDropdown"
          :tableLabel="tableLabel"
          @edit="editFirstLevel"
        ></common-table>
        <div class="layoutBorder">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    <!-- 点击事件触发 -->
    <el-dialog title="编辑用户组" :visible.sync="dialogFormVisibleEdit">
      <el-form :model="formEdit">
        <el-form-item label="用户组名" label-width="120px">
          <el-input
            v-model="formEdit.group_name"
            autocomplete="off"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户组信息" label-width="120px">
          <el-input v-model="formEdit.group_info" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="updateFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="创造云监控" :visible.sync="dialogFormVisibleAdd">
      <el-form :model="formAdd">
        <el-form-item label="云监控名" label-width="120px">
          <el-input
            v-model="formAdd.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="高清url" label-width="120px">
          <el-input v-model="formAdd.high_hls_url" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="标清url" label-width="120px">
          <el-input v-model="formAdd.low_hls_url" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="createFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>



<script>

import { garage_monitor_get,garage_monitor_create,garage_monitor_delete } from "@/api/manage/business/garage/garage.js";
import CommonTable from "frontelementpackage/src/CommonTableDropdown.vue";



export default {
  name: "start",
  data() {
    return {
      query: {
        querypage: 1, //获取页码
        pagesize: 10, //每页返回数量
        garage_id: this.$route.query.garage_id, //搜索 可搜邮箱 用户名 手机号
      },
      //分页数据
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData: [],
      dialogFormVisibleEdit: false,
      formEdit: { group_info: "" },
      dialogFormVisibleAdd: false,
      formAdd: {},
      tableLabel: [
        {
          prop: "id",
          label: "id",
          width: 100,
        },
        {
          prop: "high_hls_url",
          label: "高清源",
          width: 450,
        },
        {
          prop: "low_hls_url",
          label: "标清源",
          width: 450,
        },
      ],
      tableDropdown: [
        {
          id: "1",
          name: "高清",
        },
        {
          id: "2",
          name: "标清",
        },
        {
          id: "3",
          name: "删除该频道",
        },
       
      ]
    };
  },
  components: {
    CommonTable
  },
  created() {
      this.getFirstLevel();
  },
  methods: {
    editFirstLevel(row,methods) {
      console.log(row,methods)
      if(methods=="高清"){
        //不知道干啥，先写一下
        alert("高清")
      }
      if(methods=="标清"){
        //不知道干啥，先写一下
        alert("标清")
      }
      if(methods=="删除该频道"){
        //不知道干啥，先写一下
        this.delMonitor(row)
      }
      if(methods=="增加"){
        //不知道干啥，先写一下
        alert("增加")
      }
    },
    editUser(row) {
      this.formEdit = {
        group_id: row.id,
        group_name: row.group_name,
        group_info: row.group_info,
      };
      console.log(this.form,"ddd")
      this.dialogFormVisibleEdit = true;
    },
    delMonitor(row) {
      let ids = row.id;
      console.log({ monitoring_id: ids });
      this.$confirm("是否确定删除", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "删除",
        cancelButtonText: "放弃删除",
      })
        .then(() => {
          garage_monitor_delete({ monitoring_id: ids }).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功删除");
              this.getFirstLevel();
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃删除" : "停留在当前",
          });
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query = {
        querypage: this.currentPage, 
        pagesize: this.pagesize , 
        keyword: "", 
      };
      //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
      // user_group_manage_index(this.query).then((res) => {
      //   console.log(this.tableData, "new");
      //   this.tableData = res.data.result;
      // });
    },
    handleSizeChange(val) {
      this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
      // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
      this.query = {
        querypage: this.currentPage, 
        pagesize: this.pagesize, 
        keyword: "", 
      };
      console.log(this.query)
      // user_group_manage_index(this.query).then((res) => {
      //   console.log(this.tableData, "new");
      //   this.tableData = res.data.result;
      // });
    },

    createFirstLevel() {
      //拼接提交参数
      this.formAdd["garage_id"] = this.$parent.$route.query.garage_id
     console.log(this.formAdd)
      garage_monitor_create(this.formAdd).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功添加");
          this.getFirstLevel();
        } else {
          this.$message.info(res.msg);
        }
      });
      this.dialogFormVisibleAdd = false;
    },
    addFormButton() {
      this.dialogFormVisibleAdd = true;
    },
    updateFirstLevel() {
  
      console.log(this.formEdit)
      // user_group_manage_update(this.formEdit).then((res) => {
      //   if (res.code == 200) {
      //     this.$message.success("成功修改");
      //     window.location.reload();
      //   } else {
      //     this.$message.info(res.msg);
      //   }
      // });
      this.dialogFormVisibleEdit = false;
    },
    getFirstLevel() {
      garage_monitor_get(this.query).then((res) => {
        
        this.tableData = res.data.result;
        console.log(this.tableData, "new");
      });
    },
  },
};
</script>



<style lang="scss" scoped>
</style>